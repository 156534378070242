import React from 'react'
import Card from './Card'

export default function DeckList({ decklist } ) {
  return (
      decklist.map(card => {
        return <Card key={card.id} card={card} />
      })
  )
}

