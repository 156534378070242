import React from 'react';
import { useNavigate } from 'react-router-dom';
import PointsList from '../components/PointsList'

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import "../App.css";
import Header from '../Header';

export default function DisplayPointList() {
    const pointlist=JSON.parse(localStorage.getItem('pointlist'));
    const navigate = useNavigate();
    console.log(pointlist);
    return (
        <Container maxWidth="sm">
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            <Header/>
            <h3>Points List</h3>
            <PointsList pointlist={pointlist}/>
            <Button variant="contained" onClick={()=>navigate("/")}>Back To Calculator</Button>
        </Typography>
        </Box>
        </Container>
    )
  }