import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DisplayPointList from "./routes/DisplayPointList";
import Calculator from "./routes/Calculator";

export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Calculator />} />
          <Route path="/pointlist" element={<DisplayPointList />} />
          <Route render={() => <h1>Not found!</h1>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}