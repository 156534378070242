import React from 'react'
import Point from './Point'

export default function PointsList( {pointlist}) {
  return (
    pointlist.map(pointrecord => {
        return <Point key={pointrecord.Name} pointrecord={pointrecord}/>
    })
  )
}
