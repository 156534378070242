import React from 'react'
import Card from './Card'

export default function DeckListPoints({decklist}) {
    return (
        decklist.filter(card => card.points > 0).map(card => {
            return <Card key={card.id} card={card} />
          })
    )
  }
