import React from 'react'
import Card from './Card'

const exceptions=['Mountain','Mountains','Swamp','Swamps','Plain','Plains','Island','Islands','Forest','Forests']

function checkExceptions(name) {
    console.log("CHecking exceptions for '" + name + "'");
    return exceptions.includes(name);
}

export default function DeckListTooMany( {decklist}) {
    return (
        decklist.filter(card => (card.quantity > 1) && (!checkExceptions(card.name))).map(card => {
            return <Card key={card.id} card={card} />
          })
    )
}
