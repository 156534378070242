import React from 'react'

export default function Point({pointrecord}) {
  if (pointrecord.Name !== "") {

    return (
        <div className='card green'>
        {pointrecord.Name} - {pointrecord.Points} Points
        </div>
    )
    } else {
        return ("")

    }
}
