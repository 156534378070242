import React from 'react'

export default function Card({ card }) {
  let className="card green";
  if (card.points > 0) {
    className="card yellow";
  }
  return (
    <div className={className}>
    {card.quantity} x {card.name} ({card.points} Points)
    </div>
  )
}
