import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DeckList from '../components/DeckList';
import DeckListPoints from '../components/DeckListPoints';
import { readRemoteFile   } from 'react-papaparse';
import DeckListTooMany from '../components/DeckListTooMany';
import ErrorList from '../components/ErrorList';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import "../App.css";
import Header from '../Header';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://www.greatwhitenorthsoftware.com/">
        Great White North Software
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Calculator() {
    const [pointlist, setPointList] = useState([]);
    const [cardlistraw, setCardListRaw] = useState(localStorage.getItem('cardlistraw'))
    const [decklist, setDeckList] = useState([]);
    const [totalpoints, setTotalPoints] = useState(0);
    const [totalcards, setTotalCards] = useState(0);
    const [errorlist, setErrorList] = useState([]);
    const [initialStartup, setInitialStartup] = useState(0);
    const navigate = useNavigate();

    var commonConfig = {delimiter: ","};
  
    if (initialStartup === 0) {
      loadPoints();
      setInitialStartup(1);
    }
  
    useEffect(() => {
    },[])
  
    useEffect(() => {
    },[decklist])
  
    function reset () {
      setCardListRaw("")
      localStorage.setItem('cardlistraw',"")
      setDeckList([])
    }
  
    function loadPoints() {
      readRemoteFile(
        "HighlanderPointsList.csv",
        {
          ...commonConfig,
          header: true,
          download: true,
          complete: (result) => {
            setPointList(result.data);
            localStorage.setItem('pointlist',JSON.stringify(result.data));
            console.log('---------------------------');
            console.log('Results:', result);
            console.log('Data:', result.data);
            console.log('---------------------------');        
          }
        }
      );    
    }
  
    function lookupPoints(name) {
      //console.log('Pointlist length ' + pointlist.length)
      for (let i=0; i<pointlist.length; i++) {
        //console.log(pointlist[i]);
        //console.log("Checking '" + name + "' = '" + pointlist[i].Name + "'")
        if (pointlist[i].Name.trim() === name.trim()) {
          return parseInt(pointlist[i].Points);
        }
      }  
      return 0;
    }
  
    function calculatePoints(e) {
      const cards= cardlistraw;
  
      if (cards === '') return;
      //console.log(cards);
  
      // parse card list
      var cardlist=cards.split(/\r?\n/);
      var tempdecklist = [];
      var temptotalpoints = 0;
      var temptotalcards = 0;
      //console.log(typeof cardlist);
     
      for (let i=0; i<cardlist.length; i++) {
        var card=String(cardlist[i]).trim();
        if ((card !== "") && (!card.startsWith('//'))) {
          let [description]=card.split('(',2);
          if (description) {
            //console.log(description);
            let [quantity,...name] =description.split(' ');
            if (name[0].toUpperCase() === 'X') {
              name[0]="";
            }
            var cardname=name.join(" ").trim();
            let points=lookupPoints(cardname);
            temptotalpoints=temptotalpoints+points;
            temptotalcards=temptotalcards+parseInt(quantity);
            //console.log('Qty ' + quantity + ' name ' + cardname);
            tempdecklist.push({id:i, name:cardname, quantity, points})
          }
        }
      }
  
      setDeckList(tempdecklist) ;
      setTotalPoints(temptotalpoints);
      let newerrorlist=[];
      if (temptotalpoints > 10 ) {
        newerrorlist.push("You have " + temptotalpoints + " which is more than the maximum of 10 points allowed");
      }
      setTotalCards(temptotalcards);
      if (temptotalcards !== 100) {
        newerrorlist.push("You must have exactly 100 cards.  You currently have " + temptotalcards)
      }
      setErrorList(newerrorlist);
    }
  
  
    return (
    
      <>
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          <Header/>
        <h3>Enter your deck list</h3>
        <div className='normal'>Enter the cards in your deck in the format "quantity name".
          For example "1 Sol Ring".  Also acceptabe is "1 x Sol Ring". 
          Some programs also add extra information at the end of the line. 
          Everything after and including the first "(" is ignored.</div>
        
        <TextField id="inputarea" multiline size="small" fullWidth 
          value={cardlistraw}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCardListRaw(event.target.value);
            localStorage.setItem('cardlistraw',event.target.value);
          }}>
          </TextField>
        <Button variant="contained" onClick={loadPoints}>Reload Points List</Button>
        <Button variant="contained" onClick={reset}>Reset</Button>
        <Button variant="contained" onClick={calculatePoints}>Calculate</Button>
        <Button variant="contained" onClick={()=>navigate("/pointlist")}>Show Point List</Button>
        <div className="cards"><h3>Card List</h3>
          <div className='cards'><DeckList decklist={decklist} /></div>
        </div>
        <div><h3>Cards With Points</h3>
          <div className='cards'><DeckListPoints decklist={decklist} /></div>
        </div>
        <div><h3>Duplicate Cards</h3>
          <div className='cards'><DeckListTooMany decklist={decklist} /></div>
        </div>
        <div>
        <h3>Summary</h3>
        </div>
        <div className='normal' >You have {totalpoints} points in {totalcards} cards
          <ErrorList errorlist={errorlist}/>
        </div>
        </Typography>
        <Copyright />
        </Box>
    </Container>
      </>
    );
  }


  
