import React from 'react'

export default function Header() {
  return (
    <div id='header'>
    <div id='logo'><img src="highlander.png" /></div>
    <div id='title'><h1>Canadian Highlander Deck Calculator</h1></div>
  </div>
)
}
